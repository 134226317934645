import { useEffect } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";

import { EventProps, trackCustomEvent } from "../utilities/analytics";

type InViewOptions = Pick<IntersectionOptions, "threshold" | "triggerOnce">;
type TrackEventOptions = Pick<EventProps, "action" | "category" | "label">;
type Options = InViewOptions & TrackEventOptions;

export default function useFireGAEventInView({
  action,
  category,
  label,
  threshold,
  triggerOnce = true,
}: Options) {
  const { ref, inView } = useInView({ threshold, triggerOnce });
  useEffect(() => {
    if (inView) {
      trackCustomEvent({ action, category, label, nonInteraction: true });
    }
  }, [action, category, inView, label]);

  return ref;
}

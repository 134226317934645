/* istanbul ignore file */

import cx from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { BenefitsListDataFragment } from "../../../graphql-types";
import useFireGAEventInView from "../../hooks/useFireGAEventInView";

type Props = BenefitsListDataFragment;

export default function BenefitsList({ benefits, colour }: Props) {
  const ref = useFireGAEventInView({
    action: "Section in view",
    category: "Engagement",
    label: "Benefits list",
  });

  return (
    <div ref={ref} className="md:max-w-5xl mx-auto px-4" data-testid="Benefits">
      <div className="flex flex-wrap justify-center gap-4">
        {benefits.map(({ id, image, title }) => (
          <figure
            className={cx(
              "grow-0 shrink-0 basis-[calc(50%-theme(spacing[4])/2)] md:basis-[calc(25%-theme(spacing[4]))] flex flex-col gap-4 p-4 items-center rounded-lg border-secondary-500 border-l-2 border-b-2 shadow-md text-center",
              {
                // [`bg-${colour}`]: colour, // this dynamic class isn't "seen" by Tailwind
                "bg-primary-500": !colour,
              }
            )}
            style={{
              backgroundColor: colour ?? "",
            }}
            key={id}
          >
            {image.localFile.publicURL && (
              <img
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={image.description!}
                className="p-4 max-w-full rounded-lg w-auto mx-auto h-40"
                src={image.localFile.publicURL}
              />
            )}
            {title?.trim() && (
              <figcaption className="text-md text-white">
                {title.trim()}
              </figcaption>
            )}
          </figure>
        ))}
      </div>
    </div>
  );
}

export const fragment = graphql`
  fragment BenefitsListData on BenefitsList {
    colour
    benefits {
      id
      title
      image {
        description
        localFile {
          publicURL
        }
      }
    }
  }
`;
